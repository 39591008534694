import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
	Modal,
	VStack,
	Heading,
	Image,
	Text,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	Button,
} from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'

const ModalPlaylistLoop = ({ onClose, isOpen }) => {
	const { src, srcset } = useImageGenerator({
		path: '/assets/images/graphics/vidday-playlist-non-stop-tunes',
		ext: 'png',
	})
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="lg">
			<ModalOverlay />

			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image boxSize="80px" src={src} srcSet={srcset} alt="Device Orientation" />
						<Heading size="lg" variant="hero">
							Non-stop tunes.
						</Heading>
						<Text>
							Your selection of background music will seamlessly loop to fill the length of your video.
						</Text>
						<Text>Continue adding songs if you do not want looping to occur.</Text>
						<Button onClick={onClose}>Okay, Thanks</Button>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ModalPlaylistLoop
