import React, { useState, useEffect, useMemo } from 'react'
import {
	chakra,
	Box,
	Container,
	Heading,
	VStack,
	Text,
	HStack,
	Button,
	SimpleGrid,
	Divider,
	Flex,
	useDisclosure,
	StylesProvider,
	useMultiStyleConfig,
} from '@chakra-ui/react'
import loadable from '@loadable/component'
import Card from '../../../../atoms/Card'
import { AllInclusiveRounded, InfoRounded, PlaylistAddRounded, AddRounded } from '../../../../atoms/Icon'
import ModalPlaylistLoop from '../../../../organisms/ModalPlaylistLoop'
import { usePlaylistContext } from '../../../../../contexts/PlaylistProvider'
import arrayMove from 'array-move'

import {
	NavCard,
	NavCardContent,
	NavCardCover,
	NavCardActions,
	NavCardButton,
	NavWrapper,
} from '../../../../atoms/NavigationCard'
import { useVideoDurationCalc } from '../../../../../hooks/useVideoDurationCalc'
import { useMedia } from '../../../../../hooks/useMedia'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { FeatureItemFullWidth } from '../../../../molecules/FeatureItem'

const MusicGridDraggable = loadable(
	() => import(/* webpackPrefetch: true */ '../../../SceneryMusic/src/MusicGridDraggable'),
	{
		ssr: false,
	}
)

export const formatTime = (seconds) => {
	if (seconds > 3600) {
		// ✅ get hh:mm:ss string
		return new Date(seconds * 1000).toISOString().slice(11, 19)
	}
	// ✅ if seconds are less than 1 hour and you only need mm:ss
	else {
		var time = new Date(seconds * 1000).toISOString().slice(14, 19)
		if (seconds < 600) {
			// Remove first zero like m:ss
			return time.slice(1)
		} else {
			return time
		}
	}
	// return [
	// 	Math.floor((seconds % 3600) / 60), // minutes
	// 	('00' + Math.floor(time % 60)).slice(-2), // seconds
	// ].join(':')
}

const Playlist = ({ onAddSong, ...rest }) => {
	const styles = useMultiStyleConfig('NavCard', rest)
	const media = useMedia()
	const { event } = useEventContext()
	const { isOpen, onClose, onOpen } = useDisclosure()
	const { trackIds, savePlaylist, selectedTracks, hasCustomSong } = usePlaylistContext()
	const { durationSec } = useVideoDurationCalc(media, event.themeTemplateId)

	const getDuration = () => {
		let duration = 0
		selectedTracks?.forEach((el) => (duration = duration + el.duration))
		return duration
	}

	const onSortEnd = ({ oldIndex, newIndex }) => {
		const items = arrayMove(trackIds, oldIndex, newIndex)
		savePlaylist(items)
	}

	const hasSongs = selectedTracks?.length > 0

	const isLoop = durationSec > getDuration()

	const Title = !hasSongs ? 'No Music' : 'Background Music'

	const feature = {
		path: '/assets/images/graphics/vidday-upload-own-song',
		imgTransform: ['translate(1rem, 0)', 'none'],
		content: () => (
			<chakra.div maxW={[null, 'calc(100% - 80px)']}>
				<Text variant="title">Did you know?</Text>
				<Text pt=".25rem" line-height="normal">
					You can upload your own songs to use as background music.
				</Text>
			</chakra.div>
		),
		onClick: onAddSong,
		buttonText: 'Upload a Song',
		// Used for tracking
		btnProps: {
			id: 'custom-song-call-out',
		},
	}

	const Description = () => {
		if (hasSongs) {
			return (
				<chakra.span>
					<chakra.strong>
						{selectedTracks?.length} song{selectedTracks?.length > 1 ? 's' : ''} (
						{formatTime(getDuration())})
					</chakra.strong>{' '}
					{isLoop && 'on loop'}{' '}
					{isLoop && (
						<chakra.span>
							<AllInclusiveRounded color="gray.500" onClick={onOpen} />{' '}
							<InfoRounded
								width=".875rem"
								height=".875rem"
								mt="-0.125rem"
								color="blue.500"
								onClick={onOpen}
							/>
						</chakra.span>
					)}
				</chakra.span>
			)
		} else {
			return <chakra.strong>0 song</chakra.strong>
		}
	}

	return (
		<StylesProvider value={styles}>
			<Card w="full" {...rest} onClick={!hasSongs ? onAddSong : () => {}}>
				<NavWrapper>
					<NavCardCover
						path={
							hasSongs
								? '/assets/images/graphics/playlist-activated'
								: '/assets/images/graphics/playlist-deactivated'
						}
						borderBottomLeftRadius={hasSongs ? 'none' : 'inherit'}
					/>
					<NavCardContent title={Title} description={Description()} />
					{!hasSongs && (
						<NavCardActions>
							<NavCardButton>
								<AddRounded color="link" />
							</NavCardButton>
						</NavCardActions>
					)}
					<ModalPlaylistLoop isOpen={isOpen} onClose={onClose} />
				</NavWrapper>
				{hasSongs && (
					<Flex
						align="center"
						justify="center"
						w="full"
						py="1rem"
						borderBottom={'1px solid #DADDE1'}
						borderTop={'1px solid #DADDE1'}>
						<Button variant="outline" onClick={onAddSong}>
							<PlaylistAddRounded mr="0.25rem" /> Add a Song
						</Button>
					</Flex>
				)}
				{hasSongs && <MusicGridDraggable collection={selectedTracks} onSortEnd={onSortEnd} useDragHandle />}
			</Card>
			{!hasCustomSong && <FeatureItemFullWidth color="white" item={feature} overflow="hidden" />}
		</StylesProvider>
	)
}

export default Playlist
